import { createReducer } from "@reduxjs/toolkit";

export const reducer = createReducer(
{
    pdfList: [],
    caseStudyList: [],
    videoList: [],
    loading: false,
},
{
    addContent:(state, payload)=>{
        state.pdfList = payload.payload['pdf'];
        state.caseStudyList = payload.payload["caseStudy"];
        state.videoList = payload.payload["video"];
    },
    removeContent:(state)=>{
        state.pdfList = [];
        state.caseStudyList = [];
        state.videoList = [];
    },
    setLoader: (state, payload) =>{
        state.loading = payload.payload
    }
});