import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import React from "react";

function AddPdf() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const initialRef = React.useRef(null)

    return (
        <>
            <Button onClick={onOpen}>Add PDF</Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add PDF</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Year</FormLabel>
                            <Input ref={initialRef} placeholder='Year' />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Subject</FormLabel>
                            <Input placeholder='Subject' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='purple' mr={3}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddPdf;