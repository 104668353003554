import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";
import img4 from "../assets/4.jpg";
import img5 from "../assets/5.png";
import { Box, Container, Heading, Image, Stack, Text } from '@chakra-ui/react';

const headingOptions = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textTransform: "uppercase",
    padding: "4",
}
function Home() {
    return (
        <Box>
            <MyCarousel />

            <Container
                maxWidth={"container.xl"}
                minHeight={'140vh'}
                padding={"16"}
            >
                <Heading
                    textTransform={'uppercase'}
                    width={'fit-content'}
                    paddingY={'2'}
                    borderBottom={'2px solid'}
                    margin={"auto"}
                >
                    Services
                </Heading>
                <Stack
                    height={'full'}
                    padding={'4'}
                    alignItems={'center'}
                    direction={['column', 'row']}
                >
                    <Image src={img5} height={['40', '400']} filter={'hue-rotate(-130deg)'} />
                    <Text letterSpacing={'widest'} lineHeight={'190%'} padding={['4', '16']} textAlign={'center'}>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit earum corporis mollitia quod
                        quasi neque? Sint ea enim in rem sed voluptates consequuntur asperiores sit placeat facilis, blanditiis
                        voluptatem deserunt. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit earum corporis mollitia quod
                        quasi neque? Sint ea enim in rem sed voluptates consequuntur asperiores sit placeat facilis, blanditiis
                        voluptatem deserunt. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit earum corporis mollitia quod
                        quasi neque? Sint ea enim in rem sed voluptates consequuntur asperiores sit placeat facilis, blanditiis
                        voluptatem deserunt. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit earum corporis mollitia quod
                        quasi neque? Sint ea enim in rem sed voluptates consequuntur asperiores sit placeat facilis, blanditiis
                        voluptatem deserunt.
                    </Text>
                </Stack>
            </Container>
        </Box>
    )
}

const MyCarousel = () => {
    return (
        <Carousel
            autoPlay
            infiniteLoop
            interval={1000}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
        >
            <Box width={'full'} height={'100vh'}>
                <Image src={img1} height={'full'} width={'full'} objectFit={'cover'}/>
                <Heading backgroundColor={"blackAlpha.600"} color={"white"} {...headingOptions}>
                    Watch The Future
                </Heading>
            </Box>

            <Box width={'full'} height={'100vh'}>
                <Image src={img2} height={'full'} width={'full'} objectFit={'cover'}/>
                <Heading backgroundColor={"whiteAlpha.600"} color={"black"} {...headingOptions}>
                    Future is Gaming
                </Heading>
            </Box>

            <Box width={'full'} height={'100vh'}>
                <Image src={img3} height={'full'} width={'full'} objectFit={'cover'}/>
                <Heading backgroundColor={"whiteAlpha.600"} color={"black"} {...headingOptions}>
                    Gaming on Console
                </Heading>
            </Box>

            <Box width={'full'} height={'100vh'}>
                <Image src={img4} height={'full'} width={'full'} objectFit={'cover'}/>
                <Heading backgroundColor={"whiteAlpha.600"} color={"black"} {...headingOptions}>
                    Night Life is cool
                </Heading>
            </Box>
        </Carousel>
    );
}

export default Home