import { Box, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import img from '../assets/subject.png'
import { useNavigate } from 'react-router-dom'

function Subjects(props) {
    const navigate = useNavigate();
    let subjects = [];
    for (const item of props.pdfList) {
        if (item.year === props.year && !subjects.includes(item.subject)) {
            subjects.push(item.subject);
        }
    }
    const onSubjectClick = (subject) => {
        navigate(`/pdflist/${subject}`);
    }
    return (
        <VStack>
            <Heading
                textTransform={'uppercase'}
                width={'fit-content'}
                paddingY={'2'}
                borderBottom={'2px solid'}
                margin={"auto"}
            >
                Subjects
            </Heading>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
                {subjects.map((item, index) => (
                    <Box key={index} m={4} textAlign="center" width={"350px"}>

                        <VStack onClick={() => onSubjectClick(item)}>
                            <Image src={img} alt={item} boxSize="250px" width={"250px"} />
                            <Text fontSize="lg" fontWeight="bold" noOfLines={2}>
                                {item}
                            </Text>
                        </VStack>


                    </Box>
                ))}
            </Box>
        </VStack>
    )
}

export default Subjects