import { Heading, VStack, Container, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import Loader from './Loader';
import { useSelector } from 'react-redux';
import YearButton from './YearButton';
import Subjects from './Subjects';
import AddPdf from './AddPdf';

const Years = () => {

    let { loading } = useSelector(state => state.content);
    
    let { pdfList } = useSelector(state => state.content);
    let [year, setYear] = useState("1");
    return (
        <VStack>
            <HStack>
            <Heading
                textTransform={'uppercase'}
                width={'fit-content'}
                paddingY={'2'}
                borderBottom={'2px solid'}
                margin={"auto"}
            >
                Study Notes
            </Heading>
            {/* <AddPdf/> */}
            </HStack>
            <Container maxW={"container.xl"}>
                {loading || pdfList.length === 0
                    ? <Loader />
                    : <> {year === "0" ? setYear(pdfList[0].year) : null}
                        <YearButton pdfList={pdfList} onClick = {(index)=> setYear(index)}/>
                        <Subjects pdfList={pdfList} year={year}/>
                    </>
                }
            </Container>
        </VStack>
    )
}

export default Years