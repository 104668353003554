import React from 'react'
import { Box, Container, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { getThumbnail } from '../utility/convertUrlToId'
import img from '../assets/playlist.svg';
import { useSelector } from 'react-redux';
import Loader from './Loader';

function VideoPlaylist() {
    const { loading, videoList } = useSelector(state => state.content);

    return (
        <VStack>
            <Heading
                textTransform={'uppercase'}
                width={'fit-content'}
                paddingY={'2'}
                borderBottom={'2px solid'}
                margin={"auto"}
            >
                PlayLists
            </Heading>
            <Container maxWidth={"container.xl"}>
                {loading
                    ? <Loader />
                    : <PlayLists videoList={videoList} />
                }
            </Container>
        </VStack>
    )
}

const PlayLists = (props) => {
    let playlist = [];
    for (const item of props.videoList) {
        if (!playlist.includes(item.playlist)) {
            playlist.push(item.playlist)
        }
    }

    playlist.reverse();

    const getUrl = (name) => {
        let url = "";
        for (const item of props.videoList) {
            if (item.playlist === name) {
                url = item.url;
                break;
            }
        }
        return url
    }
    const getCount = (name) => {
        let count = 0;
        for (const item of props.videoList) {
            if (item.playlist === name) {
                count = count + 1;
            }
        }
        return count
    }
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
            {playlist.map((video, index) => (
                <Box key={index} m={4} textAlign="center" width={"350px"}>

                    <VStack>
                        <Link to={`/videolist/${video}`}>
                            <Image src={getThumbnail(getUrl(video))} alt={video} boxSize="100%" width={"350px"} />
                            <HStack>
                                <Box borderRadius={'0 0 10px 10px'} height={'10'} width={'full'} backgroundColor={'red.100'} alignItems={'flex-start'}>
                                    <HStack height={'full'} width={'full'} justifyContent={'space-between'}>
                                        <Image src={img} alt='playlist' boxSize="100%" width={'full'} />
                                        <Text width={'full'}>
                                            {getCount(video)} Videos
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>
                            <Text fontSize="lg" fontWeight="bold" noOfLines={2}>
                                {video}
                            </Text>
                        </Link>
                    </VStack>

                </Box>
            ))}
        </Box>
    )
}



export default VideoPlaylist