import { Box } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

const PDFViewer = () => {
    const location = useLocation();
    const pdfUrl = location.state && location.state.pdfUrl;
  return (
    <Box padding={"4"}>
      <embed src={pdfUrl} type="application/pdf" width="100%" height="700px" />
    </Box>
  );
};

export default PDFViewer;
