import { Navigate, /*Outlet*/ } from 'react-router-dom';
import Videos from '../components/Videos';
import Profile from '../components/Profile';
import Home from '../components/Home';
import Login from '../components/Login';
import VideoList from '../components/VideoList';
import VideoPlaylist from '../components/VideoPlaylist';
import Years from '../components/Years';
import CaseStudy from '../components/CaseStudy';
import PDFViewer from '../components/PDFViewer';
import PdfList from '../components/PdfList';

    const routes = (user) => [
      { // public routes
        path: '/',
        element: <Home /> 
      },
      {
        path: '/videos', // protected routes
        element: user != null ? <VideoPlaylist /> : <Navigate to="/login" />,
        // children: [
        //   { path: '/dashboard', element: <Dashboard /> },
        //   { path: '/account', element: <Account /> },
        //   { path: '/', element: <Navigate to="/app/dashboard" /> },
        //   {
        //     path: 'member',
        //     element: <Outlet />,
        //     children: [
        //       { path: '/', element: <MemberGrid /> },
        //       { path: '/add', element: <AddMember /> },
        //     ],
        //   },
        // ],
      },
      {
        path: '/profile', // protected routes
        element: user != null ? <Profile /> : <Navigate to="/login" />,
      },
      { path: 'login', element: user == null ? <Login /> : <Navigate to={"/profile"} /> },
      {
        path: '/video', // protected routes
        element: user != null ? <Videos /> : <Navigate to="/login" />,
      },
      {
        path: '/videolist/:name', // protected routes
        element: user != null ? <VideoList /> : <Navigate to="/login" />,
      },
      {
        path: '/studynotes', // protected routes
        element: user != null ? <Years /> : <Navigate to="/login" />,
      },
      {
        path: '/casestudy', // protected routes
        element: user != null ? <CaseStudy /> : <Navigate to="/login" />,
      },
      {
        path: '/pdfviewer', // protected routes
        element: user != null ? <PDFViewer /> : <Navigate to="/login" />,
      },
      {
        path: '/pdflist/:subject', // protected routes
        element: user != null ? <PdfList /> : <Navigate to="/login" />,
      },
    ];
    
    export default routes;