import axiosInstance from "./axiosInstance"; // Import the instance you created

export const getLogin = () => async (dispatch) => {
  try {
    dispatch({
      type: "setLoader",
      payload: true
    });
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
        "name": user.displayName,
        "email": user.email,
        "avatar": user.photoURL,
        "uid": user.uid
    }
    const response = await axiosInstance.post("/user/getLogin", data); // Use your API endpoint
    if(response.status === 200){
        const token = response.headers["token"];
        localStorage.setItem("token", token);
        dispatch(getContent());
    }else{
        console.error("Error:", response);
    }
  } catch (error) {
    console.error("Error:", error);
  }
  dispatch({
    type: "setLoader",
    payload: false
  });
};

export const getContent = () => async (dispatch) => {
    try {
      dispatch({
        type: "setLoader",
        payload: true
      });
      const response = await axiosInstance.get("/getContent"); // Use your API endpoint
        if(response.status === 200){
            dispatch({
                type: "addContent",
                payload: {...response.data}
            });
        }else{
            console.error("Error:", response);
        }
    } catch (error) {
      console.error("Error:", error);
    }
    dispatch({
      type: "setLoader",
      payload: false
    });
};