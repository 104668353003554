import React from 'react';
import { Box, Button, Icon, Image, Text } from '@chakra-ui/react';
import image1 from '../assets/ic_launcher.png';
import { FaGoogle } from 'react-icons/fa';
import { signInWithGoogle } from '../utility/Firebase';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLogin } from '../services/apiservices';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

    const handleGoogleLogin = async() => {
      try {
        const user = await signInWithGoogle();
        if(user){
          dispatch(getLogin());
          navigate("/");
        }
      } catch (error) {
        alert("Failed to do Login, Please Try Again");
      }
    };
  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Image
          src={image1} // Replace with your logo image URL
          alt="My App Logo"
          boxSize="100px" // Adjust the size as needed
          mb={4}
        />
        <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign={'center'}>
        Hey there, Wel-Come to Rx Pharma
          <br />
          Login in your account to continue
        </Text>
        <Button
          colorScheme="purple"
          size="lg"
          onClick={handleGoogleLogin}
          leftIcon={<Icon as={FaGoogle} w={6} h={6} />}
        >
          Sign in with Google
        </Button>
      </Box>
  )
}

export default Login