import React from 'react'
import { signOut } from '../utility/Firebase'
import { Box, Button, Icon, Image, Text } from '@chakra-ui/react'
import { FaGoogle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Profile() {
    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleGoogleLogout = async() =>{
        try {
            await signOut();
            dispatch({
                type: "removeContent",
              });
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            >
            <Image
                src={user.photoURL} // Replace with your logo image URL
                alt="Profile Picture"
                boxSize="100px" // Adjust the size as needed
                mb={4}
                borderRadius={'full'}
            />
            <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign={'center'}>
            Welcome back {user.displayName}
            </Text>
            <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign={'center'}>
            Email :- {user.email}
            </Text>
            <Button
                colorScheme="purple"
                size="lg"
                onClick={handleGoogleLogout}
                leftIcon={<Icon as={FaGoogle} w={6} h={6} />}
            >
                Sign Out
            </Button>
        </Box>
    )
}

export default Profile