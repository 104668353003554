// Converts a fully qualified YouTube URL to a video ID.
// If the video ID is passed as a URL, no conversion is done.
export function convertUrlToId(url, trimWhitespaces = true) {
    if (!url.includes("http") && url.length === 11) return url;
    if (trimWhitespaces) url = url.trim();
  
    const expressions = [
      /^https:\/\/(?:www\.|m\.)?youtube\.com\/watch\?v=([_\-a-zA-Z0-9]{11}).*$/,
      /^https:\/\/(?:music\.)?youtube\.com\/watch\?v=([_\-a-zA-Z0-9]{11}).*$/,
      /^https:\/\/(?:www\.|m\.)?youtube\.com\/shorts\/([_\-a-zA-Z0-9]{11}).*$/,
      /^https:\/\/(?:www\.|m\.)?youtube(?:-nocookie)?\.com\/embed\/([_\-a-zA-Z0-9]{11}).*$/,
      /^https:\/\/youtu\.be\/([_\-a-zA-Z0-9]{11}).*$/,
    ];
  
    for (const exp of expressions) {
      const match = url.match(exp);
      if (match && match[1]) return match[1];
    }
  
    return null;
  }
  
  // Grabs a YouTube video's thumbnail for the provided url.
export function getThumbnail(url, quality = 'standard') {
    const videoId = convertUrlToId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  }
  