import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLogin } from "./services/apiservices";
import { useEffect } from "react";
import Main from "./components/Main";

function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  useEffect(() => {
    if(user){
      dispatch(getLogin());
    }
  }, [dispatch, user]);

  return (
    <BrowserRouter>
      <Header />
      <Main/>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
