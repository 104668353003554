import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react'

function YearButton(props) {
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
            <YearButtonText text = "1st Year" onClick ={() => props.onClick("1")}/>
            <YearButtonText text = "2nd Year" onClick ={() => props.onClick("2")}/>
            <YearButtonText text = "3rd Year" onClick ={() => props.onClick("3")}/>
            <YearButtonText text = "4th Year" onClick ={() => props.onClick("4")}/>
            <YearButtonText text = "5th Year" onClick ={() => props.onClick("5")}/>
        </Box>
    )
}

function YearButtonText(props) {
  return (
    <Box m={4} textAlign="center" width={"350px"}>
                    <Button width={"full"} onClick={ () => props.onClick()}>
                    <Text>{props.text}</Text>
                    </Button>

                </Box>
  )
}

export default YearButton