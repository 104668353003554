import axios from "axios";

// Create an Axios instance
const instance = axios.create({
  baseURL: "https://rxpharma.nsplayer.in/v1", // Replace with your API base URL
});

// Add an interceptor to include authorization headers
instance.interceptors.request.use(
  (config) => {
    // Get the token from your authentication system (localStorage, cookies, etc.)
    const token = localStorage.getItem("token"); // Change this to match your setup

    // If a token is available, set it in the request headers
    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;