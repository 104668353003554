import { useRoutes } from "react-router-dom";
import routes from "../utility/routes";

function Main() {
    const user = JSON.parse(localStorage.getItem("user"));
  
    const routing = useRoutes(routes(user));
  
    return (
      <>
        {routing}
      </>
    );
}

export default Main