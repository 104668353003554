import { Box, Button, HStack, Heading, Image, Text, VStack, Container } from '@chakra-ui/react'
import React from 'react'
import img from '../assets/pdf.png'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from './Loader';
import { useSelector } from 'react-redux';

function PdfList() {
    const navigate = useNavigate();
    const params = useParams();
    let { pdfList } = useSelector(state => state.content);
    let { loading } = useSelector(state => state.content);

    const onPdfClick = (url) => {
        navigate("/pdfviewer", {
            state: { pdfUrl: url },
        });
    }

    const getTopicList = (subject) => {
        let topic = []
        for (const item of pdfList) {
            if(item.subject === subject){
                topic.push(item)
            }
        }
        return topic;
    }
    return (
        <VStack>
            <Heading
                textTransform={'uppercase'}
                width={'fit-content'}
                paddingY={'2'}
                borderBottom={'2px solid'}
                margin={"auto"}
            >
                Study Note
            </Heading>
            <Container maxW={"container.xl"}>
                {loading || pdfList.length === 0
                    ? <Loader />
                    : <>  <Box display="flex" flexWrap="wrap" justifyContent="center">
                        {getTopicList(params.subject).map((item, index) => (
                            <Box key={index} m={4} textAlign="center" width={"350px"}>

                                <VStack>
                                    <VStack onClick={() => onPdfClick(item.pdfurl)}>
                                        <Image src={img} alt={item.pdfname} boxSize="250px" width={"250px"} />
                                        <Text fontSize="lg" fontWeight="bold" noOfLines={2}>
                                            {item.pdfname}
                                        </Text>
                                    </VStack>
                                    {/* <HStack
                                        width={'full'}
                                        justifyContent={'space-evenly'}
                                    >
                                        <Button colorScheme='purple' width={'full'}>
                                            Update
                                        </Button>
                                        <Button colorScheme='red' width={'full'}>
                                            Delete
                                        </Button>
                                    </HStack> */}
                                </VStack>

                            </Box>
                        ))}
                    </Box>
                    </>
                }
            </Container>
        </VStack>
    )
}

export default PdfList