import { Button, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom';

function Videos() {

    // const videolist = [
    //     {"name": "Lecture 1", "url": "https://youtu.be/WibcvWT7KQQ?si=muD2grAshW1tKOnJ"},
    //     {"name": "Lecture 2", "url": "https://youtu.be/UbMgcdmYC70?si=K1MxDsyK3r0ZEUSN"},
    //     {"name": "Lecture 3", "url": "https://youtu.be/6mr4cYJ7yew?si=kHEvFi8Jx31m6Rkz"},
    //     {"name": "Lecture 4", "url": "https://youtu.be/ElZfdU54Cp8?si=k29SyNZZoaBpE2gy"},
    //     {"name": "Lecture 5", "url": "https://youtu.be/_2clW8Zxq88?si=0-sP0sIUf_s3fo-d"},
    //     {"name": "Lecture 6", "url": "https://youtu.be/k-atPa3QUis?si=u9lX0ZlALhdZIkyk"},
    //     {"name": "Lecture 7", "url": "https://youtu.be/uSb0M_UQE1o?si=sL7b4jKI188O-TJT"},
    // ];
    const location = useLocation()
    const index= location.state && location.state.index;
    const videolist = location.state && location.state.videoList;
    const [videoSrc, setVideo] = useState(videolist[index])
  return (
    <Stack direction={['column', 'row']} height={'100vh'}>
        <VStack width={'full'} alignItems={'flex-start'} padding={'8'} overflowY={'auto'}>
            <ReactPlayer
             controls
             width={'100%'}
             height={'100%'}
             url={videoSrc.url} />
            <Heading>{videoSrc.name}</Heading>
            {/* <Text>This is the sample video description</Text> */}
        </VStack>
        <VStack width={['full', 'xl']}
            padding={'8'}
            spacing={'8'}
            alignItems={'stretch'}
            overflowY={'auto'}
        >
            {videolist.map((item, index) => {
               return <Button key={index} onClick={()=>setVideo(item)} variant={'ghost'} colorScheme='purple'>
               <Text isTruncated>{item.name}</Text>
                </Button> 
            })}
        </VStack>
    </Stack>
  )
}

export default Videos