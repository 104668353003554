import { Box, Button, Container, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { getThumbnail } from '../utility/convertUrlToId'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Loader from './Loader';

function VideoList() {
    const param = useParams();
    const navigate = useNavigate();
    const { loading, videoList } = useSelector(state => state.content)

    const getVideoList = () => {
        let videoListNew = [];
        for (const item of videoList) {
            console.log(item.playlist)
            if (item.playlist.trim() === param.name.trim()) {
                videoListNew.push(item);
            }
        }
        return videoListNew;
    }
    const onVideoClick = (index) => {
        navigate("/video", {
            state: { index: index, videoList: getVideoList() },
        });
    }
    return (
        <VStack>
            <Heading
                textTransform={'uppercase'}
                width={'fit-content'}
                paddingY={'2'}
                borderBottom={'2px solid'}
                margin={"auto"}
            >
                Videos
            </Heading>

            <Container maxWidth={"container.xl"}>
                {
                    loading
                        ? <Loader />
                        : <Box display="flex" flexWrap="wrap" justifyContent="center">
                            {getVideoList().map((video, index) => (
                                <Box key={index} m={4} textAlign="center" width={"350px"}>

                                    <VStack onClick={() => onVideoClick(index)}>
                                        <Image src={getThumbnail(video.url)} alt={video.name} boxSize="100%" width={"350px"} />
                                        <Text fontSize="lg" fontWeight="bold" noOfLines={2}>
                                            {video.name}
                                        </Text>
                                        {/* <HStack
                                            width={'full'}
                                            justifyContent={'space-evenly'}
                                        >
                                            <Button colorScheme='purple' width={'full'}>
                                                Update
                                            </Button>
                                            <Button colorScheme='red' width={'full'}>
                                                Delete
                                            </Button>
                                        </HStack> */}
                                    </VStack>

                                </Box>
                            ))}
                        </Box>
                }
            </Container>
        </VStack>
    )
}

export default VideoList