// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNK0dtyvGvYSxiMQfOlI92dBmyEd312VA",
  authDomain: "rx-pharma-82b45.firebaseapp.com",
  projectId: "rx-pharma-82b45",
  storageBucket: "rx-pharma-82b45.appspot.com",
  messagingSenderId: "203908127030",
  appId: "1:203908127030:web:e70ba11d38976102853b97",
  measurementId: "G-085SHPL25P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = async()=> {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        logEvent(analytics, "Login");
        localStorage.setItem("user", JSON.stringify(user));
        return user;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const signOut = async()=> {
    try {
        await auth.signOut();
        localStorage.clear();
    } catch (error) {
        throw error;
    }
};