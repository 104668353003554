import { Box, Button, HStack, Heading, Image, Input, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import {AiOutlineSend} from 'react-icons/ai';
import img1 from '../assets/googleplay.png';

function Footer() {
  return (
    <Box backgroundColor={'blackAlpha.900'} minHeight={'40'} padding={'16'} color={'white'}>
        <Stack direction={['column', 'row']}>
            <VStack alignItems={'stretch'} width={'full'} paddingX={'4'}>
                <Heading size={'md'} textTransform={'uppercase'}>
                    Subscribe to get updates
                </Heading>
                <HStack borderBottom={'2px solid white'} py={'2'}>
                    <Input 
                        placeholder='Enter Email Here...'
                        border={'none'}
                        borderRadius={'none'}
                        outline={'none'}
                        focusBorderColor='none'
                    />
                    <Button
                        padding={'0'}
                        colorScheme='purple'
                        variant={'ghost'}
                        borderRadius={'0px 20px 20px 0'}
                    >
                        <AiOutlineSend/>
                    </Button>
                </HStack>
            </VStack>

            <VStack width={'full'}
             borderLeft={['none', '1px solid white']}
             borderRight={['none', '1px solid white']}
            >
                <Heading textAlign={'center'} textTransform={'uppercase'}>
                    Rx Pharma
                </Heading>
                <Text>All Rights Reserved</Text>
            </VStack>

            <VStack width={'full'}>
                <Heading size={'md'} textTransform={'uppercase'}>
                    App link
                </Heading>
                <a target='blank' href='https://play.google.com/store/apps/details?id=com.nstechno.pfdekho'><Image height={'20'} width={'full'} fit={'cover'} src={img1}/></a>
            </VStack>
        </Stack>
    </Box>
  )
}

export default Footer